// .shop-list PHONE
$shop-list-padding-bottom-phone: 50;
$shop-list-padding-bottom-tablet: 110;

// .page-template-shop #main-content PHONE
$shop-main-content-padding-top-phone: 100;

// .shop-item PHONE
$shop-item-margin-bottom-phone: 100;

// .shop-item:nth-child(3n + 2):last-child DESKTOP
$shop-item-nth-3n-2-last-child-margin-right-desktop: calc(#{grid-width(4, 12, $desktop-gutter, $desktop-outer)} + #{$desktop-gutter}px);

// .shop-item-title PHONE
$shop-item-title-font-size-phone: 32;
$shop-item-title-line-height-phone: 40;
$shop-item-title-margin-bottom-phone: 25;

// .shop-item-link PHONE
$shop-item-link-margin-bottom-phone: 75;

// item-link PHONE
$item-link-font-size-phone: 10;
$item-link-line-height-phone: 21;

// .shop-item-image PHONE
$shop-item-image-min-height-phone: 224;

// .shop-item-image TABLET
$shop-item-image-min-height-tablet: 242;

// .shop-item-image DESKTOP
$shop-item-image-min-height-desktop: 258;

// .shop-view-more-wrapper PHONE
$shop-view-more-wrapper-padding-top-phone: 50;
$shop-view-more-wrapper-padding-bottom-phone: 50;

// .shop-view-more PHONE
$shop-view-more-font-size-phone: 10;
$shop-view-more-line-height-phone: 21;

.page-template-shop {
  background-color: $black;
  color: $white;

  #main-content {
    padding-top: #{$shop-main-content-padding-top-phone + $header-height-phone}px;

    @media #{media-min-width($tablet)} {
      padding-top: #{$shop-main-content-padding-top-phone + $header-height-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      padding-top: #{$shop-main-content-padding-top-phone + $header-height-desktop}px;
    }
  }

  .shop-list {
    @media #{media-min-width($tablet)} {
      @include margin-left-right-auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: grid-width(12, 12, $tablet-gutter, $tablet-outer);
    }

    @media #{media-min-width($desktop)} {
      justify-content: flex-start;
      width: grid-width(12, 12, $desktop-gutter, $desktop-outer);
    }

    .shop-item {
      @include margin-left-right-auto;
      margin-bottom: #{$shop-item-margin-bottom-phone}px;
      width: grid-width(4, 4, $phone-gutter, $phone-outer);

      @media #{media-min-width($tablet)} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 0;
        margin-right: 0;
        max-width: grid-width(6, 12, $tablet-gutter, $tablet-outer);
        min-width: grid-width(6, 12, $tablet-gutter, $tablet-outer);
      }

      @media #{media-min-width($desktop)} {
        max-width: grid-width(4, 12, $desktop-gutter, $desktop-outer);
        min-width: grid-width(4, 12, $desktop-gutter, $desktop-outer);
      }

      &:nth-child(3n + 1),
      &:nth-child(3n + 2) {
        @media #{media-min-width($desktop)} {
          margin-right: #{$desktop-gutter}px;
        }
      }

      .shop-item-title {
        @include schnyder-cond-l-light;
        font-size: #{$shop-item-title-font-size-phone}px;
        line-height: #{$shop-item-title-line-height-phone}px;
        margin-bottom: #{$shop-item-title-margin-bottom-phone}px;
        text-align: center;

        a {
          color: $white;
          transition: color 300ms;

          &:hover {
            color: $dark-gray;
          }
        }
      }

      .shop-item-title-image {
        line-height: 0;
        margin-bottom: #{$shop-item-title-margin-bottom-phone}px;

        .shop-title-image {
          max-width: 100%;
          width: auto;
          height: auto;
          display: block;
          @include margin-left-right-auto;
        }
      }

      .shop-item-link {
        margin-bottom: #{$shop-item-link-margin-bottom-phone}px;
        text-align: center;

        .item-link {
          @include brown-normal;
          color: $white;
          font-size: #{$item-link-font-size-phone}px;
          letter-spacing: #{$item-link-font-size-phone / 3.33}px;
          line-height: #{$item-link-line-height-phone}px;
          text-transform: uppercase;
          transition: color 300ms;

          &:hover {
            color: $dark-gray;
          }
        }
      }

      .shop-item-image {
        @include background-cover-center;
        height: 54.10628019vw;
        min-height: #{$shop-item-image-min-height-phone}px;
        width: 100%;

        @media #{media-min-width($tablet)} {
          height: 31.51041667vw;
          min-height: #{$shop-item-image-min-height-tablet}px;
        }

        @media #{media-min-width($desktop)} {
          height: 20.15625vw;
          min-height: #{$shop-item-image-min-height-desktop}px;
        }
      }
    }
  }

  .shop-list-hide {
    display: none;
  }

  .shop-view-more-wrapper {
    padding-bottom: #{$shop-view-more-wrapper-padding-bottom-phone}px;
    padding-top: #{$shop-view-more-wrapper-padding-top-phone}px;
    text-align: center;

    .shop-view-more {
      @include brown-normal;
      color: $white;
      cursor: pointer;
      display: inline-block;
      font-size: #{$shop-view-more-font-size-phone}px;
      letter-spacing: #{$shop-view-more-font-size-phone / 3.33}px;
      line-height: #{$shop-view-more-line-height-phone}px;
      text-transform: uppercase;
      transition: color 300ms;

      &:hover {
        color: $dark-gray;
      }

      &.hide {
        display: none;
      }
    }
  }

  .shop-splash {
    .shop-splash-title {
      @include margin-left-right-auto;
      @include schnyder-cond-l-light;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 50px;
      text-align: center;
      width: grid-width(4, 4, $phone-gutter, $phone-outer);

      @media #{media-min-width($tablet)} {
        width: grid-width(8, 12, $tablet-gutter, $tablet-outer);
      }

      @media #{media-min-width($desktop)} {
        width: grid-width(6, 12, $desktop-gutter, $desktop-outer);
      }
    }

    .shop-splash-subtitle {
      @include brown-normal;
      @include margin-left-right-auto;
      font-size: 10px;
      letter-spacing: #{$shop-view-more-font-size-phone / 3.33}px;
      line-height: 10px;
      margin-bottom: 75px;
      text-align: center;
      text-transform: uppercase;
      width: grid-width(4, 4, $phone-gutter, $phone-outer);

      @media #{media-min-width($tablet)} {
        width: grid-width(8, 12, $tablet-gutter, $tablet-outer);
      }

      @media #{media-min-width($desktop)} {
        width: grid-width(6, 12, $desktop-gutter, $desktop-outer);
      }
    }

    .mc-field-name,
    .mc-field-email {
      @include canela-text-light;
      @include margin-left-right-auto;
      @include placeholder($white);
      border-bottom: solid 1px $white-50;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      line-height: 16px;
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 20px;
      width: grid-width(4, 4, $phone-gutter, $phone-outer);

      @media #{media-min-width($tablet)} {
        width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
      }

      @media #{media-min-width($desktop)} {
        width: grid-width(10, 12, $desktop-gutter, $desktop-outer);
      }
    }

    .mc-field-name {
      border-top: solid 1px $white-50;
    }

    .mc-field-email {
      margin-bottom: 234px;
    }

    .mc-field-submit {
      @include brown-normal;
      border-top: solid #{$mc-field-name-email-border-size-phone}px $white-50;
      bottom: 0;
      cursor: pointer;
      display: block;
      font-size: #{$mc-field-submit-phone-font-size-phone}px;
      left: 0;
      letter-spacing: #{$mc-field-submit-phone-font-size-phone / 3.33}px;
      line-height: #{$mc-field-submit-phone-font-size-phone}px;
      padding-bottom: #{$mc-field-submit-phone-padding-bottom-phone}px;
      padding-top: #{$mc-field-submit-phone-padding-top-phone}px;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      transition: color 300ms;
      width: 100%;

      &:hover {
        color: $dark-gray;
      }
    }
  }
}
