// style-cover-height PHONE
$style-cover-height-phone: 268;

// style-cover-height TABLET
$style-cover-height-tablet: 720;

// style-cover-height DESKTOP
$style-cover-height-desktop: 640;

.page-template-style {
  background-color: $black;

  .style-cover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50.5vh;
    min-height: #{$style-cover-height-phone}px;
    width: 100%;

    @media #{media-min-width($tablet)} {
      height: 70.3vh;
      min-height: #{$style-cover-height-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      height: 80vh;
      min-height: #{$style-cover-height-desktop}px;
    }
  }
}
