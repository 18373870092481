// private-access-form PHONE
$private-access-form-padding-top-phone: 80;

// access-form-title PHONE
$access-form-title-font-size-phone: 10;

// password-form-password PHONE 
$password-form-password-border-size-phone: 1;
$password-form-password-font-size-phone: 16;
$password-form-password-padding-bottom-phone: 16;
$password-form-password-padding-left-right-phone: 10;
$password-form-password-padding-top-phone: 20;

// password-form-submit PHONE
$password-form-submit-border-size-phone: 1;
$password-form-submit-font-size-phone: 10;
$password-form-submit-padding-bottom-phone: 42;
$password-form-submit-padding-top-phone: 50;
$password-form-submit-height-phone: $password-form-submit-font-size-phone + $password-form-submit-padding-top-phone + $password-form-submit-padding-bottom-phone + (2 * $password-form-submit-border-size-phone);

// password-form-submit TABLET
$password-form-submit-padding-bottom-tablet: 32;
$password-form-submit-padding-top-tablet: 34;
$password-form-submit-height-tablet: $password-form-submit-font-size-phone + $password-form-submit-padding-top-tablet + $password-form-submit-padding-bottom-tablet + (2 * $password-form-submit-border-size-phone);

// form-wrong-password-message PHONE
$form-wrong-password-message-margin-top-phone: 24;
$form-wrong-password-message-padding-bottom-phone: 14;
$form-wrong-password-message-padding-left-right-phone: 40;
$form-wrong-password-message-padding-top-phone: 18;

//form-wrong-password-message-archive-password PHONE
$form-wrong-password-message-private-password-form-margin-top-phone: 30;

//form-wrong-password-message-archive-password TABLET
$form-wrong-password-message-private-password-form-margin-top-tablet: 24;

//message-cont-wrapper PHONE
$message-cont-wrapper-font-size-phone: 16;
$message-cont-wrapper-line-height-phone: 26;

// private-password-form PHONE
$private-password-form-margin-top-phone: 50;

.private-access-form {
  box-sizing: border-box;
  min-height: calc(100vh - #{$header-height-phone + $footer-height-phone}px);
  padding-bottom: #{$private-access-form-padding-top-phone + $password-form-submit-height-phone}px;
  padding-top: #{$private-access-form-padding-top-phone}px;
  position: relative;

  @media #{media-min-width($tablet)} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - #{$header-height-tablet + $footer-height-tablet}px);
    padding-bottom: #{$private-access-form-padding-top-phone + $password-form-submit-height-tablet}px;
  }

  .access-form-title {
    @include brown-normal;
    @include margin-left-right-auto;
    font-size: #{$access-form-title-font-size-phone}px;
    letter-spacing: #{$access-form-title-font-size-phone / 3.33}px;
    text-align: center;
    text-transform: uppercase;
    width: grid-width(4, 4, $phone-gutter, $phone-outer);

    @media #{media-min-width($tablet)} {
      width: grid-width(12, 12, $tablet-gutter, $tablet-outer);
    }
  }

  .form-wrong-password-message {
    @include margin-left-right-auto;
    background-color: $white;
    box-sizing: border-box;
    margin-top: #{$form-wrong-password-message-margin-top-phone}px;
    padding-bottom: #{$form-wrong-password-message-padding-bottom-phone}px;
    padding-left: #{$form-wrong-password-message-padding-left-right-phone}px;
    padding-right: #{$form-wrong-password-message-padding-left-right-phone}px;
    padding-top: #{$form-wrong-password-message-padding-top-phone}px;
    width: grid-width(4, 4, $phone-gutter, $phone-outer);

    @media #{media-min-width($tablet)} {
      width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
    }

    @media #{media-min-width($desktop)} {
      width: grid-width(6, 12, $desktop-gutter, $desktop-outer);
    }

    &+ .private-password-form {
      margin-top: #{$form-wrong-password-message-private-password-form-margin-top-phone}px;

      @media #{media-min-width($tablet)} {
        margin-top: #{$form-wrong-password-message-private-password-form-margin-top-tablet}px;
      }
    }

    .message-cont-wrapper {
      @include canela-text-light;
      color: $black;
      display: block;
      font-size: #{$message-cont-wrapper-font-size-phone}px;
      line-height: #{$message-cont-wrapper-line-height-phone}px;
      text-align: center;
      width: 100%;

      @media #{media-min-width($tablet)} {
        @include margin-left-right-auto;
        width: grid-width(6, 12, $tablet-gutter, $tablet-outer);
      }

      @media #{media-min-width($desktop)} {
        width: grid-width(4, 12, $desktop-gutter, $desktop-outer);
      }
    }
  }

  .private-password-form {
    margin-top: #{$private-password-form-margin-top-phone}px;

    .password-form {
      .password {
        @include canela-text-light;
        @include margin-left-right-auto;
        @include placeholder($white);
        border-bottom: solid #{$password-form-password-border-size-phone}px $white-50;
        border-top: solid #{$password-form-password-border-size-phone}px $white-50;
        box-sizing: border-box;
        display: block;
        font-size: #{$password-form-password-font-size-phone}px;
        line-height: #{$password-form-password-font-size-phone}px;
        padding-bottom: #{$password-form-password-padding-bottom-phone}px;
        padding-left: #{$password-form-password-padding-left-right-phone}px;
        padding-right: #{$password-form-password-padding-left-right-phone}px;
        padding-top: #{$password-form-password-padding-top-phone}px;
        width: grid-width(4, 4, $phone-gutter, $phone-outer);

        @media #{media-min-width($tablet)} {
          width: grid-width(8, 12, $tablet-gutter, $tablet-outer);
        }

        @media #{media-min-width($desktop)} {
          width: grid-width(6, 12, $desktop-gutter, $desktop-outer);
        }
      }

      .submit {
        @include brown-normal;
        border-top: solid #{$password-form-submit-border-size-phone}px $white-50;
        bottom: 0;
        display: block;
        font-size: #{$password-form-submit-font-size-phone}px;
        left: 0;
        letter-spacing: #{$password-form-submit-font-size-phone / 3.333}px;
        line-height: #{$password-form-submit-font-size-phone}px;
        padding-bottom: #{$password-form-submit-padding-bottom-phone}px;
        padding-top: #{$password-form-submit-padding-top-phone}px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @media #{media-min-width($tablet)} {
          padding-bottom: #{$password-form-submit-padding-bottom-tablet}px;
          padding-top: #{$password-form-submit-padding-top-tablet}px;
        }
      }
    }
  }
}
