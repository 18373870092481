// footer PHONE
$footer-padding-phone: 50;
$footer-height-phone: 232;

// footer TABLET
$footer-padding-tablet: 40;
$footer-height-tablet: 182;

// contact-list PHONE
$contact-list-margin-padding-phone: 40;
$lists-font-size-phone: 10;
$lists-line-height-phone: 21;

// contact-list TABLET
$contact-list-margin-padding-tablet: 30;

#footer {
  background-color: $black;
  color: $white;
  padding-bottom: #{$footer-padding-phone}px;
  padding-top: #{$footer-padding-phone}px;
  position: relative;

  @media #{media-min-width($tablet)} {
    padding-bottom: #{$footer-padding-tablet}px;
    padding-top: #{$footer-padding-tablet}px;
  }

  &:before {
    background-color: $white-50;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .contact-list {
    @include brown-normal;
    font-size: #{$lists-font-size-phone}px;
    letter-spacing: #{$lists-line-height-phone / 7}px;
    line-height: #{$lists-line-height-phone}px;
    margin-bottom: #{$contact-list-margin-padding-phone}px;
    padding-bottom: #{$footer-padding-phone}px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @media #{media-min-width($phone)} {
      @include margin-left-right-auto;
      width: grid-width(4, 4, $phone-gutter, $phone-outer);
    }

    @media #{media-min-width($tablet)} {
      margin-bottom: #{$contact-list-margin-padding-tablet}px;
      padding-bottom: #{$contact-list-margin-padding-tablet}px;
      width: grid-width(6, 12, $tablet-gutter, $tablet-outer);
    }

    @media #{media-min-width($desktop)} {
      width: grid-width(4, 12, $desktop-gutter, $desktop-outer);
    }

    .contact-item {
      a {
        color: $white;
        transition: color 300ms;

        &:hover {
          color: $dark-gray;
        }
      }
    }

    &:after {
      background-color: $white-50;
      bottom: 0;
      content: '';
      height: 1px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 100vw;
    }
  }

  .social-list {
    @include margin-left-right-auto;
    display: flex;
    font-size: 0;
    justify-content: space-between;
    line-height: 0;
    width: grid-width(4, 4, $phone-gutter, $phone-outer);

    @media #{media-min-width($phone)} {
      width: calc(#{grid-width(4, 12, $tablet-gutter, $tablet-outer)} + #{$tablet-gutter * 4}px);
    }

    @media #{media-min-width($desktop)} {
      width: calc(#{grid-width(2, 12, $desktop-gutter, $desktop-outer)} + #{$desktop-gutter * 4}px);
    }

    .social-item {
      display: block;
      font-size: 0;
      height: #{$lists-line-height-phone}px;
      line-height: 0;
      width: #{$lists-line-height-phone}px;

      svg {
        fill: $white;
        width: 100%;
        height: 100%;
        display: block;
        transition: fill 300ms;

        &:hover {
          fill: $dark-gray;
        }
      }

      .social-link {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }

  [scroll-animated] {
    opacity: 0 !important;
    transition: opacity 300ms;

    &.visible {
      opacity: 1 !important;
    }
  }
}
