// client-lists-gallery PHONE
$client-lists-gallery-padding-bottom-phone: 111;
$client-lists-gallery-padding-top-phone: 75;

// client-lists-gallery TABLET
$client-lists-gallery-padding-bottom-tablet: 120;

// client-lists-gallery DESKTOP
$client-lists-gallery-padding-bottom-desktop: 95;

// client-list-title-font PHONE
$client-list-title-font-size-phone: 10;
$client-list-title-line-height-phone: 21;
$client-list-title-margin-bottom-phone: 30;

// client-list-title-font TABLET
$client-list-title-margin-bottom-tablet: 50;

// client-list-title-font DESKTOP
$client-list-title-margin-bottom-desktop: 30;

// client-list PHONE
$client-list-margin-bottom-phone: 72;

// client-list TABLET
$client-list-margin-bottom-tablet: 75;

// client-link-font-size PHONE
$client-link-font-size-phone: 30;
$client-link-line-height-phone: 48;

// client-link-font-size TABLET
$client-link-font-size-tablet: 32;
$client-link-line-height-tablet: 50;

.client-lists-gallery {
  @include margin-left-right-auto;
  padding-bottom: #{$client-lists-gallery-padding-bottom-phone}px;
  padding-top: #{$client-lists-gallery-padding-top-phone}px;
  text-align: center;
  width: grid-width(4, 4, $phone-gutter, $phone-outer); 

  @media #{media-min-width($tablet)} {
    padding-bottom: #{$client-lists-gallery-padding-bottom-tablet}px;
    width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
  }

  @media #{media-min-width($desktop)} {
    display: flex;
    justify-content: space-between;
    padding-bottom: #{$client-lists-gallery-padding-bottom-desktop}px;
    width: grid-width(12, 12, $desktop-gutter, $desktop-outer);
  }

  .client-list-wrapper {
    margin-bottom: 72px;
    width: 100%;

    @media #{media-min-width($desktop)} {
      max-width: grid-width(4, 12, $desktop-gutter, $desktop-outer);
      min-width: grid-width(4, 12, $desktop-gutter, $desktop-outer);
    }

    &:last-child {
      margin-bottom: 0;
    }

    .client-list-title {
      @include brown-normal;
      font-size: #{$client-list-title-font-size-phone}px;
      letter-spacing: #{$client-list-title-font-size-phone / 3.33}px;
      line-height: #{$client-list-title-line-height-phone}px;
      margin-bottom: #{$client-list-title-margin-bottom-phone}px;
      text-transform: uppercase;

      @media #{media-min-width($tablet)} {
        margin-bottom: #{$client-list-title-margin-bottom-tablet}px;
      }

      @media #{media-min-width($desktop)} {
        margin-bottom: #{$client-list-title-margin-bottom-desktop}px;
      }

      a {
        transition: color 300ms;

        &:hover {
          color: $dark-gray;
        }
      }
    }

    .client-list {
      margin-bottom: #{$client-list-margin-bottom-phone}px;

      @media #{media-min-width($tablet)} {
        margin-bottom: #{$client-list-margin-bottom-tablet}px;
      }

      @media #{media-min-width($desktop)} {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .client-item {
        .client-link,
        .client-text {
          @include schnyder-cond-l-light;
          font-size: #{$client-link-font-size-phone}px;
          line-height: #{$client-link-line-height-phone}px;
          text-transform: uppercase;
          transition: color 300ms;

          @media #{media-min-width($tablet)} {
            font-size: #{$client-link-font-size-tablet}px;
            line-height: #{$client-link-line-height-tablet}px;
          }
        }

        .client-link {
          &:hover {
            color: $dark-gray;
          }
        }
      }
    }
  }
}

.page-template-bridal {
  .client-lists-gallery {
    color: $black;

    .client-list-wrapper {
      .client-list-title {
        a {
          color: $black;
        }
      }
    }

    .client-list {
      .client-item {
        .client-link,
        .client-text {
          color: $black;
        }
      }
    }
  }
}

.page-template-home {
  .client-lists-gallery {
    color: $white;

    .client-list-wrapper {
      .client-list-title {
        a {
          color: $white;
        }
      }
    }

    .client-list {
      .client-item {
        .client-link,
        .client-text {
          color: $white;
        }
      }
    }
  }
}
