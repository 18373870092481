@mixin reset-margin-paddding {
  margin: 0;
  padding: 0;
}

@mixin font-inherit {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

html {
  overflow-x: hidden;
  width: 100%;

  body {
    @include reset-margin-paddding;
    font-family: sans-serif;
    position: relative;
    overflow-x: hidden;
    max-width: 100vw;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include font-inherit;
      @include reset-margin-paddding;
    }

    p {
      @include reset-margin-paddding;
    }

    blockquote {
      @include reset-margin-paddding;
    }

    ul {
      list-style: none;
      @include reset-margin-paddding;

      li {
        list-style: none;
        @include reset-margin-paddding;
      }
    }

    a {
      @include font-inherit;
      text-decoration: none;
    }

    button,
    input[type=submit] {
      @include font-inherit;
      @include reset-margin-paddding;
      appearance: button;
      background: transparent;
      border: none;
      outline: none;
      overflow: visible;
      text-transform: none;
    }

    input[type=text],
    input[type=password],
    input[type=email] {
      @include font-inherit;
      @include reset-margin-paddding;
      appearance: button;
      background: transparent;
      border: none;
      outline: none;
      overflow: visible;
      text-transform: none;
    }
  }
}
