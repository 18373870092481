// PATHS
$image-path: '../assets/images/';

// COLORS
$white: #ffffff;
$dark-gray: #969696;
$black: #000000;
$white-50: rgba(255, 255, 255, 0.5);

// BREAKPOINTS
$phone: 375;
$tablet: 768;
$desktop: 1280;

// GUTTER
$phone-gutter: 10;
$tablet-gutter: 16;
$desktop-gutter: $tablet-gutter;

// OUTER
$phone-outer: 50;
$tablet-outer: 80;
$desktop-outer: 150;

// Z-INDEX
@mixin z-moon { z-index: 9999; }
@mixin z-space { z-index: 999; }
@mixin z-sky { z-index: 99; }
@mixin z-hills { z-index: 9; }
@mixin z-floor { z-index: 0; }
@mixin z-underground { z-index: -9; }
@mixin z-bedrock { z-index: -99; }

// FONT FAMILY
@mixin schnyder-cond-l-light {
  font-family: 'Schnyder Cond L';
  font-weight: 300;
  font-style: normal;
}
@mixin schnyder-cond-l-bold {
  font-family: 'Schnyder Cond L';
  font-weight: bold;
  font-style: normal;
}
@mixin schnyder-cond-l-normal {
  font-family: 'Schnyder Cond L';
  font-weight: normal;
  font-style: normal;
}
@mixin canela-text-light {
  font-family: 'Canela Text';
  font-weight: 300;
  font-style: normal;
}
@mixin brown-normal {
  font-family: Brown;
  font-weight: normal;
  font-style: normal;
}

// MIXINS
@mixin margin-left-right-auto {
  margin-left: auto;
  margin-right: auto;
}
@mixin placeholder($c: $black) {
  &::-webkit-input-placeholder {
    color: $c;
  }

  &:-moz-placeholder {
    color: $c;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $c;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $c;
  }

  &::-ms-input-placeholder {
    color: $c;
  }

  &::placeholder {
    color: $c;
  }
}
@mixin background-cover-center {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// FUNCTIONS
@function media-min-width($width) {
  @return '(min-width: #{$width}px)';
}
@function responsive($value1, $value2, $width1: 320, $width2: 1920) {
  $x1: $width1;
  $x2: $width2;
  $y1: $value1;
  $y2: $value2;
  $a: ($y2 - $y1) / ($x2 - $x1);
  $b: ($x2 * $y1 - $x1 * $y2) / ($x2 - $x1);
  @return calc(#{$a * 100vw} + #{$b * 1px});
}
@function grid-width($cols: 1, $maxCols: 1, $gutter: 0, $outer: 0) {
  @return calc((((100vw - (#{2 * $outer}px) - (#{$maxCols - 1} * #{$gutter}px)) / #{$maxCols}) * #{$cols}) + #{$gutter * ($cols - 1)}px);
}
