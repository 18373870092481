#grid {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  padding: 0 #{$phone-outer}px;

  @media #{media-min-width($tablet)} {
    padding: 0 #{$tablet-outer}px;
  }

  @media #{media-min-width($desktop)} {
    padding: 0 #{$desktop-outer}px;
  }

  .grid-col {
    background: rgba(0, 255, 255, 0.5);
    height: 100%;
    margin-right: #{$phone-gutter}px;
    width: calc((100vw - (2 * #{$phone-outer}px) - (3 * #{$phone-gutter}px)) / 4);

    @media #{media-min-width($tablet)} {
      width: calc((100vw - (2 * #{$tablet-outer}px) - (11 * #{$tablet-gutter}px)) / 12);
      margin-right: #{$tablet-gutter}px;
    }

    @media #{media-min-width($desktop)} {
      width: calc((100vw - (2 * #{$desktop-outer}px) - (11 * #{$desktop-gutter}px)) / 12);
    }

    &:nth-child(4) {
      margin-right: 0;

      @media #{media-min-width($tablet)} {
        margin-right: #{$tablet-gutter}px;
      }
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12) {
      display: none;

      @media #{media-min-width($tablet)} {
        display: block;
      }
    }

    &:nth-child(12) {
      @media #{media-min-width($tablet)} {
        display: block;
        margin-right: 0;
      }
    }
  }
}

.vertical-guide {
  height: 100vh;
  width: 1px;
  background: #F0F;
  position: fixed;
  top: 0;
  z-index: 999999999;
}

.horizontal-guide {
  width: 100vw;
  height: 1px;
  background: #F0F;
  position: fixed;
  left: 0;
  z-index: 999999999;
}