.single-client {
  background-color: $black;
  color: $white;

  #main-content {
    padding-top: #{$header-height-phone}px;

    @media #{media-min-width($tablet)} {
      padding-top: #{$header-height-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      padding-top: #{$header-height-desktop}px;
    }
  }

  .client-header {
    padding: 54px 0 47px;
    position: relative;

    @media #{media-min-width($tablet)} {
      padding: 52px 0 47px;
    }

    @media #{media-min-width($tablet)} {
      padding: 52px 0 38px;
    }

    .back-to-gallery {
      display: block;
      height: 10px;
      position: absolute;
      right: #{($phone-outer - 10) / 2}px;
      top: 50%;
      width: 10px;

      @media #{media-min-width($tablet)} {
        right: #{($tablet-outer - 10) / 2}px;
      }

      @media #{media-min-width($desktop)} {
        right: #{($desktop-outer - 10) / 2}px;
      }

      svg {
        display: block;
        fill: none;
        height: 100%;
        stroke: $white;
        transition: stroke 300ms;
        width: 100%;

        &:hover {
          stroke: $dark-gray;
        }
      }
    }

    .client-name {
      @include margin-left-right-auto;
      @include schnyder-cond-l-light;
      font-size: 30px;
      line-height: 48px;
      text-align: center;
      text-transform: uppercase;
      width: grid-width(4, 4, $phone-gutter, $phone-outer);

      @media #{media-min-width($tablet)} {
        font-size: 32px;
        line-height: 50px;
        width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
      }

      @media #{media-min-width($desktop)} {
        width: grid-width(10, 12, $desktop-gutter, $desktop-outer);
      }
    }
  }

  .client-image-gallery,
  .client-image-gallery-masonry {
    &.hide {
      display: none;
    }

    .client-image-item {
      cursor: pointer;
      width: 100%;

      .client-image-item-file {
        display: block;
        height: auto;
        margin-bottom: 10px;
        width: 100%;

        @media #{media-min-width($desktop)} {
          margin-bottom: 16px;
        }
      }
    }
  }

  .client-image-gallery {
    display: block;
    margin-bottom: 100px;

    @media #{media-min-width($tablet)} {
      margin-bottom: 110px;
    }

    @media #{media-min-width($desktop)} {
      display: none;
    }
  }

  .client-image-gallery-masonry {
    display: none;

    @media #{media-min-width($desktop)} {
      @include margin-left-right-auto;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 79px;
      width: grid-width(12, 12, $desktop-gutter, $desktop-outer);
    }

    .image-gallery-masonry-column {
      @media #{media-min-width($desktop)} {
        max-width: grid-width(4, 12, $desktop-gutter, $desktop-outer);
        min-width: grid-width(4, 12, $desktop-gutter, $desktop-outer);
      }
    }
  }

  .client-image-slideshow {
    background-color: $black;
    display: none;
    position: relative;
    width: 100%;

    &.show {
      display: block;
    }

    .close-client-slideshow {
      @include z-hills;
      cursor: pointer;
      height: 10px;
      position: absolute;
      right: #{$phone-outer / 2}px;
      top: -70px;
      width: 10px;

      @media #{media-min-width($tablet)} {
        right: #{($tablet-outer - 10) / 2}px;
        top: -70px;
      }

      @media #{media-min-width($desktop)} {
        right: #{($desktop-outer - 10) / 2}px;
      }

      svg {
        display: block;
        fill: none;
        height: 100%;
        stroke: $white;
        transition: stroke 300ms;
        width: 100%;

        &:hover {
          stroke: $dark-gray;
        }
      }
    }

    .images-slideshow-wrapper {
      height: 50.5vh;
      overflow: hidden;
      position: relative;
      width: 100%;

      @media #{media-min-width($tablet)} {
        @include margin-left-right-auto;
        height: 56vh;
        width: grid-width(12, 12, $tablet-gutter, $tablet-outer);
      }

      @media #{media-min-width($desktop)} {
        height: 60.5vh;
        width: grid-width(12, 12, $desktop-gutter, $desktop-outer);
      }

      .image-slideshow-box {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 1000ms;
        width: 100%;

        @media #{media-min-width($tablet)} {
          max-width: grid-width(12, 12, $tablet-gutter, $tablet-outer);
          min-width: grid-width(12, 12, $tablet-gutter, $tablet-outer);
        }

        @media #{media-min-width($desktop)} {
          max-width: grid-width(12, 12, $desktop-gutter, $desktop-outer);
          min-width: grid-width(12, 12, $desktop-gutter, $desktop-outer);
        }

        &.visible {
          opacity: 1;
        }
      }
    }

    .image-slideshow-navigation {
      height: 85px;
      position: relative;
      width: 100%;

      @media #{media-min-width($tablet)} {
        height: 70px;
        position: initial;
      }

      .slideshow-navigation-info {
        @include brown-normal;
        display: inline-block;
        font-size: 10px;
        left: 50%;
        line-height: 10px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);

        @media #{media-min-width($tablet)} {
          bottom: 25px;
          top: initial;
        }
      }

      .slideshow-nav-prev,
      .slideshow-nav-next {
        cursor: pointer;
        height: 25px;
        position: absolute;
        top: 50%;
        width: 15px;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.3;

          svg {
            fill: $white;

            &:hover {
              fill: $white;
            }
          }
        }

        svg {
          display: block;
          fill: $white;
          height: 100%;
          transition: fill 300ms;
          width: 100%;

          &:hover {
            fill: $dark-gray;
          }
        }
      }

      .slideshow-nav-prev {
        left: #{($phone-outer / 2)}px;
        transform: translateY(-50%) rotate(180deg);

        @media #{media-min-width($tablet)} {
          left: #{($tablet-outer / 2) - (15 / 2)}px;
          transform: translateY(calc(-50% - 35px)) rotate(180deg);
        }

        @media #{media-min-width($desktop)} {
          left: #{($desktop-outer / 2) - (15 / 2)}px;
        }
      }

      .slideshow-nav-next {
        right: #{$phone-outer / 2}px;
        transform: translateY(-50%);

        @media #{media-min-width($tablet)} {
          right: #{($tablet-outer / 2) - (15 / 2)}px;
          transform: translateY(calc(-50% - 35px));
        }

        @media #{media-min-width($desktop)} {
          right: #{($desktop-outer / 2) - (15 / 2)}px;
        }
      }
    }
  }
}
