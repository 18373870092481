// collabs-description PHONE
$collabs-description-font-size-phone: 28;
$collabs-description-line-height-phone: 36;
$collabs-description-margin-bottom-phone: 75;
$collabs-description-padding-top-phone: 75;

// collabs-description TABLET
$collabs-description-font-size-tablet: 32;
$collabs-description-line-height-tablet: 40;

// collabs-gallery PHONE
$collabs-gallery-margin-bottom-phone: 100;

// collabs-gallery DESKTOP
$collabs-gallery-min-height-desktop: 100;

.page-template-collabs {
  background-color: $black;
  color: $white;

  #main-content {
    padding-top: #{$header-height-phone}px;

    @media #{media-min-width($tablet)} {
      padding-top: #{$header-height-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      padding-top: #{$header-height-desktop}px;
    }
  }

  .collabs-description {
    @include margin-left-right-auto;
    @include schnyder-cond-l-light;
    margin-bottom: #{$collabs-description-margin-bottom-phone}px;
    padding-top: #{$collabs-description-padding-top-phone}px;
    width: grid-width(4, 4, $phone-gutter, $phone-outer);

    @media #{media-min-width($tablet)} {
      width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
    }

    @media #{media-min-width($desktop)} {
      width: grid-width(6, 12, $desktop-gutter, $desktop-outer);
    }

    p {
      font-size: #{$collabs-description-font-size-phone}px;
      line-height: #{$collabs-description-line-height-phone}px;
      margin-bottom: #{$collabs-description-line-height-phone}px;
      text-align: center;
      width: 100%;

      @media #{media-min-width($tablet)} {
        font-size: #{$collabs-description-font-size-tablet}px;
        line-height: #{$collabs-description-line-height-tablet}px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .collabs-gallery {
    @include margin-left-right-auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: #{$collabs-gallery-margin-bottom-phone}px;
    width: grid-width(4, 4, $phone-gutter, $phone-outer);

    @media #{media-min-width($tablet)} {
      width: grid-width(12, 12, $tablet-gutter, $tablet-outer);
    }

    @media #{media-min-width($desktop)} {
      width: grid-width(12, 12, $desktop-gutter, $desktop-outer);
    }

    .collabs-item {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 16vh;
      margin-bottom: #{$phone-gutter}px;
      max-width: grid-width(2, 4, $phone-gutter, $phone-outer);
      min-width: grid-width(2, 4, $phone-gutter, $phone-outer);

      @media #{media-min-width($tablet)} {
        height: 14vh;
        max-width: grid-width(4, 12, $tablet-gutter, $tablet-outer);
        min-width: grid-width(4, 12, $tablet-gutter, $tablet-outer);
      }

      @media #{media-min-width($desktop)} {
        height: 12.5vh;
        max-width: grid-width(2.4, 12, $desktop-gutter, $desktop-outer);
        min-height: #{$collabs-gallery-min-height-desktop}px;
        min-width: grid-width(2.4, 12, $desktop-gutter, $desktop-outer);
      }

      &:nth-child(2n + 1) {
        margin-right: #{$phone-gutter}px;

        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }

      &:nth-child(3n + 1),
      &:nth-child(3n + 2) {
        @media #{media-min-width($tablet)} {
          margin-bottom: #{$tablet-gutter}px;
          margin-right: #{$tablet-gutter}px;
        }
      }

      &:nth-child(3n + 3) {
        @media #{media-min-width($tablet)} {
          margin-bottom: #{$tablet-gutter}px;
          margin-right: 0;
        }
      }

      &:nth-child(3n + 1) {
        &:nth-last-child(3) {
          @media #{media-min-width($tablet)} {
            margin-bottom: 0;
          }
        }
      }

      &:nth-child(3n + 2) {
        &:nth-last-child(2) {
          @media #{media-min-width($tablet)} {
            margin-bottom: 0;
          }
        }
      }

      &:nth-child(5n + 1),
      &:nth-child(5n + 2),
      &:nth-child(5n + 3),
      &:nth-child(5n + 4) {
        @media #{media-min-width($desktop)} {
          margin-bottom: #{$desktop-gutter}px;
          margin-right: #{$desktop-gutter}px;
        }
      }

      &:nth-child(5n + 5) {
        @media #{media-min-width($desktop)} {
          margin-bottom: #{$desktop-gutter}px;
          margin-right: 0;
        }
      }

      &:nth-child(5n + 1) {
        &:nth-last-child(5) {
          @media #{media-min-width($desktop)} {
            margin-bottom: 0;
          }
        }
      }

      &:nth-child(5n + 2) {
        &:nth-last-child(4) {
          @media #{media-min-width($desktop)} {
            margin-bottom: 0;
          }
        }
      }

      &:nth-child(5n + 3) {
        &:nth-last-child(3) {
          @media #{media-min-width($desktop)} {
            margin-bottom: 0;
          }
        }
      }

      &:nth-child(5n + 4) {
        &:nth-last-child(2) {
          @media #{media-min-width($desktop)} {
            margin-bottom: 0;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
