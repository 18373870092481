//svg-wrapper PHONE
$svg-wrapper-height-phone: 40;

//svg-wrapper TABLET
$svg-wrapper-height-tablet: 45;

// navigation PHONE
$navigation-margin-top-phone: 35;

// nav-list TABLET
$nav-list-width-tablet: calc(#{grid-width(12, 12, $tablet-gutter, $tablet-outer)} - #{$tablet-gutter * 2}px);

// nav-list DESKTOP
$nav-list-width-desktop: calc(#{grid-width(8, 12, $desktop-gutter, $desktop-outer)} + #{$desktop-gutter * 2}px);

// nav-link PHONE
$nav-link-font-size-phone: 10;

// header-height PHONE
$header-height-phone: ($phone-outer * 2) + $svg-wrapper-height-phone;

// header-height TABLET
$header-height-tablet: $phone-outer + $svg-wrapper-height-tablet + $nav-link-font-size-phone + $navigation-margin-top-phone + $navigation-margin-top-phone;

// header-height DESKTOP
$header-height-desktop: $header-height-tablet;

// close-navigation PHONE
$close-navigation-size-phone: 10;
$close-navigation-top-phone: 32;

// nav-item PHONE
$nav-item-margin-top-phone: 50;

#header {
  @include z-sky;
  background-color: $black;
  position: fixed;
  width: 100%;
  top: 0;
  transition: top 300ms;

  @media #{media-min-width($tablet)} {
    padding: #{$phone-outer}px 0 #{$navigation-margin-top-phone}px;
  }

  &.hide-logo {
    @media #{media-min-width($tablet)} {
      top: -100px;
    }
  }

  .header-logo {
    @include z-sky;
    background-color: $black;
    padding: #{$phone-outer}px 0;
    position: relative;

    @media #{media-min-width($tablet)} {
      padding: 0;
    }

    .svg-wrapper {
      svg {
        @include margin-left-right-auto;
        display: block;
        fill: $white;
        height: #{$svg-wrapper-height-phone}px;
        max-width: 100%;
        width: auto;
        transition: fill 300ms;

        @media #{media-min-width($tablet)} {
          height: #{$svg-wrapper-height-tablet}px;
        }

        &:hover {
          fill: $dark-gray;
        }
      }
    }
  }

  #navigation {
    @include z-hills;
    background-color: $black;
    color: $white;
    left: 0;
    min-height: calc(100vh - #{$header-height-phone}px);
    padding-top: 50px;
    position: fixed;
    text-align: center;
    top: -300%;
    transition: top 400ms;
    width: 100%;


    @media #{media-min-width($tablet)} {
      display: block;
      min-height: initial;
      padding-top: 0;
      position: relative;
    }

    .close-navigation {
      cursor: pointer;
      display: block;
      height: #{$close-navigation-size-phone}px;
      position: absolute;
      right: #{($phone-outer - 10) / 2}px;
      top: #{$close-navigation-top-phone}px;
      width: #{$close-navigation-size-phone}px;

      @media #{media-min-width($tablet)} {
        display: none;
      }

      svg {
        display: block;
        fill: none;
        height: 100%;
        stroke: $white;
        transition: stroke 300ms;
        width: 100%;

        &:hover {
          stroke: $dark-gray;
        }
      }
    }

    .nav-list {
      @media #{media-min-width($tablet)} {
        @include margin-left-right-auto;
        display: flex;
        justify-content: space-between;
        margin-top: #{$navigation-margin-top-phone}px;
        width: $nav-list-width-tablet;
      }

      @media #{media-min-width($desktop)} {
        width: $nav-list-width-desktop;
      }

      .nav-item {
        margin-bottom: #{$nav-item-margin-top-phone}px;

        @media #{media-min-width($tablet)} {
          display: inline-block;
          margin-bottom: 0;
        }

        .nav-link,
        .current-nav {
          @include brown-normal;
          display: block;
          font-size: #{$nav-link-font-size-phone}px;
          letter-spacing: #{$nav-link-font-size-phone / 3.33}px;
          line-height: #{$nav-link-font-size-phone}px;
          text-transform: uppercase;
          transition: color 300ms;

          &:hover {
            color: $dark-gray;
          }
        }

        .current-nav {
          color: $dark-gray;
        }
      }
    }
  }

  &:after {
    background-color: $white-50;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
    @include z-sky;
  }
}

.show-nav {
  #header {
    #navigation {
      top: #{$header-height-phone}px;

      @media #{media-min-width($tablet)} {
        top: initial;
      }
    }
  }
}