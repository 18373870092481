// login-client PHONE
$login-client-padding-top-phone: 98;

// login-client TABLET
$login-client-padding-top-tablet: 240;

// login-client DESKTOP
$login-client-padding-top-desktop: 240;

// login-title PHONE
$login-title-font-size-phone: 10;
$login-title-margin-bottom-phone: 50;

// input-email-password PHONE
$input-email-password-font-size-phone: 16;
$input-email-password-paddding-bottom-phone: 16;
$input-email-password-paddding-left-right-phone: 10;
$input-email-password-paddding-top-phone: 21;

// input-submit PHONE
$input-submit-font-size-phone: 10;
$input-submit-padding-bottom-phone: 34;
$input-submit-padding-top-phone: 31;

// login-client-form-input-submit TABLET
$input-submit-padding-bottom-tablet: 30;

// forgot-password-wrapper PHONE
$forgot-password-wrapper-margin-bottom-phone: 85;
$forgot-password-wrapper-margin-top-phone: 15;
$forgot-password-wrapper-paddding-left-right-phone: 10;

// forgot-password-wrapper TABLET
$forgot-password-wrapper-margin-bottom-tablet: 167;

// forgot-password-wrapper DESKTOP
$forgot-password-wrapper-margin-bottom-desktop: 132;

// forgot-password PHONE
$forgot-password-font-size-phone: 12.5;

.page-template-login {
  background-color: $black;
  color: $white;

  .login-client {
    padding-top: #{$login-client-padding-top-phone}px;
    width: 100%;

    @media #{media-min-width($tablet)} {
      padding-top: #{$login-client-padding-top-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      padding-top: #{$login-client-padding-top-desktop}px;
    }

    .login-title {
      @include brown-normal;
      @include margin-left-right-auto;
      font-size: #{$login-title-font-size-phone}px;
      letter-spacing: #{$login-title-font-size-phone / 3.33}px;
      margin-bottom: #{$login-title-margin-bottom-phone}px;
      text-align: center;
      text-transform: uppercase;
      width: grid-width(4, 4, $phone-gutter, $phone-outer);

      @media #{media-min-width($tablet)} {
        width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
      }

      @media #{media-min-width($tablet)} {
        width: grid-width(6, 12, $desktop-gutter, $desktop-outer);
      }
    }

    .login-client-form {
      .input-email,
      .input-password {
        @include canela-text-light;
        @include margin-left-right-auto;
        @include placeholder($white);
        border-bottom: solid 1px $white-50;
        box-sizing: border-box;
        display: block;
        font-size: #{$input-email-password-font-size-phone}px;
        padding-bottom: #{$input-email-password-paddding-bottom-phone}px;
        padding-left: #{$input-email-password-paddding-left-right-phone}px;
        padding-right: #{$input-email-password-paddding-left-right-phone}px;
        padding-top: #{$input-email-password-paddding-top-phone}px;
        width: grid-width(4, 4, $phone-gutter, $phone-outer);

        @media #{media-min-width($tablet)} {
          width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
        }

        @media #{media-min-width($desktop)} {
          width: grid-width(6, 12, $desktop-gutter, $desktop-outer);
        }

        &:first-child {
          border-top: solid 1px $white-50;
        }
      }

      .input-submit {
        border-top: solid 1px $white-50;
        cursor: pointer;
        font-size: #{$input-submit-font-size-phone}px;
        padding-bottom: #{$input-submit-padding-bottom-phone}px;
        padding-top: #{$input-submit-padding-top-phone}px;
        text-transform: uppercase;
        width: 100%;

        @media #{media-min-width($tablet)} {
          padding-bottom: #{$input-submit-padding-bottom-tablet}px;
        }
      }

      .forgot-password-wrapper {
        @include margin-left-right-auto;
        box-sizing: border-box;
        margin-bottom: #{$forgot-password-wrapper-margin-bottom-phone}px;
        margin-top: #{$forgot-password-wrapper-margin-top-phone}px;
        padding-left: #{$forgot-password-wrapper-paddding-left-right-phone}px;
        padding-right: #{$forgot-password-wrapper-paddding-left-right-phone}px;
        width: grid-width(4, 4, $phone-gutter, $phone-outer);

        @media #{media-min-width($tablet)} {
          margin-bottom: #{$forgot-password-wrapper-margin-bottom-tablet}px;
          width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
        }

        @media #{media-min-width($desktop)} {
          margin-bottom: #{$forgot-password-wrapper-margin-bottom-desktop}px;
          width: grid-width(6, 12, $desktop-gutter, $desktop-outer);
        }

        .forgot-password {
          @include canela-text-light;
          font-size: #{$forgot-password-font-size-phone}px;
        }
      }
    }
  }
}
