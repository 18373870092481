// press-presskit-download PHONE
$press-presskit-download-margin-bottom-phone: 72;
$press-presskit-download-padding-phone: 34;

// press-presskit-download TABLET
$press-presskit-download-margin-bottom-tablet: 100;
$press-presskit-download-padding-tablet: 18;

// press-presskit-download DESKTOP
$press-presskit-download-margin-bottom-desktop: 65;

// presskit-download PHONE
$presskit-download-font-size-phone: 10;
$presskit-download-line-height-phone: 11;

// presskit-download TABLET
$presskit-download-line-height-tablet: 10;

// press-clipping PHONE
$press-clipping-margin-bottom-phone: 111;

// press-clipping TABLET
$press-clipping-margin-bottom-tablet: 120;

// press-clipping-item PHONE
$press-clipping-item-margin-bottom-phone: 67;

// press-clipping-item TABLET
$press-clipping-item-margin-bottom-tablet: 75;

// clipping-item-info PHONE
$clipping-item-info-font-size-phone: 10;
$clipping-item-info-line-height-phone: 11;
$clipping-item-info-margin-bottom-phone: 35;

// clipping-item-title PHONE
$clipping-item-title-font-size-phone: 28;
$clipping-item-title-line-height-phone: 36;

// clipping-item-title TABLET
$clipping-item-title-font-size-tablet: 32;
$clipping-item-title-line-height-tablet: 40;

.page-template-press {
  background-color: $black;
  color: $white;

  #main-content {
    padding-top: #{$header-height-phone}px;

    @media #{media-min-width($tablet)} {
      padding-top: #{$header-height-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      padding-top: #{$header-height-desktop}px;
    }
  }

  .press-presskit-download-list {
    margin-bottom: #{$press-presskit-download-margin-bottom-phone}px;

    @media #{media-min-width($tablet)} {
      margin-bottom: #{$press-presskit-download-margin-bottom-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      margin-bottom: #{$press-presskit-download-margin-bottom-desktop}px;
    }

    .press-presskit-download {
      border-bottom: solid 1px $white-50;
      line-height: 0;
      padding-bottom: #{$press-presskit-download-padding-phone}px;
      padding-top: #{$press-presskit-download-padding-phone}px;
      text-align: center;
      width: 100%;

      @media #{media-min-width($tablet)} {
        padding-bottom: #{$press-presskit-download-padding-tablet}px;
        padding-top: #{$press-presskit-download-padding-tablet}px;
      }
    }

    .presskit-download {
      @include brown-normal;
      color: $white;
      display: inline-block;
      font-size: #{$presskit-download-font-size-phone}px;
      letter-spacing: #{$presskit-download-font-size-phone / 3.33}px;
      line-height: #{$presskit-download-line-height-phone}px;
      text-transform: uppercase;
      transition: color 300ms;

      @media #{media-min-width($tablet)} {
        line-height: #{$presskit-download-line-height-tablet}px;
      }

      &:hover {
        color: $dark-gray;
      }
    }
  }

  .press-clipping {
    margin-bottom: #{$press-clipping-margin-bottom-phone}px;
    width: 100%;

    @media #{media-min-width($tablet)} {
      @include margin-left-right-auto;
      margin-bottom: #{$press-clipping-margin-bottom-tablet}px;
      width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
    }

    @media #{media-min-width($desktop)} {
      width: grid-width(6, 12, $desktop-gutter, $desktop-outer);
    }

    .press-clipping-item {
      margin-bottom: #{$press-clipping-item-margin-bottom-phone}px;

      @media #{media-min-width($tablet)} {
        margin-bottom: #{$press-clipping-item-margin-bottom-tablet}px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .clipping-item-info {
        @include brown-normal;
        font-size: #{$clipping-item-info-font-size-phone}px;
        letter-spacing: #{$clipping-item-info-font-size-phone / 3.33}px;
        line-height: #{$clipping-item-info-line-height-phone}px;
        margin-bottom: #{$clipping-item-info-margin-bottom-phone}px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
      }

      .clipping-item-title {
        @include margin-left-right-auto;
        @include schnyder-cond-l-light;
        font-size: #{$clipping-item-title-font-size-phone}px;
        line-height: #{$clipping-item-title-line-height-phone}px;
        text-align: center;
        width: grid-width(4, 4, $phone-gutter, $phone-outer);

        @media #{media-min-width($tablet)} {
          font-size: #{$clipping-item-title-font-size-tablet}px;
          line-height: #{$clipping-item-title-line-height-tablet}px;
          width: 100%;
        }

        a {
          color: $white;
          transition: color 300ms;

          &:hover {
            color: $dark-gray;
          }
        }
      }
    }
  }
}
