.page-template-gallery {
  background-color: $black;
  color: $white;

  #main-content {
    padding-top: #{$header-height-phone}px;

    @media #{media-min-width($tablet)} {
      padding-top: #{$header-height-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      padding-top: #{$header-height-desktop}px;
    }
  }

  .clients-gallery,
  .clients-gallery-masonry {
    padding-top: 55px;
    width: 100%;

    @media #{media-min-width($tablet)} {
      padding-top: 52px;
    }

    @media #{media-min-width($desktop)} {
      @include margin-left-right-auto;
      display: flex;
      flex-wrap: wrap;
      width: grid-width(12, 12, $desktop-gutter, $desktop-outer);
    }

    .client-item {
      margin-bottom: 52px;

      .client-name {
        @include margin-left-right-auto;
        @include schnyder-cond-l-light;
        font-size: 30px;
        line-height: 48px;
        margin-bottom: 47px;
        text-align: center;
        text-transform: uppercase;
        width: grid-width(4, 4, $phone-gutter, $phone-outer);

        @media #{media-min-width($tablet)} {
          font-size: 32px;
          line-height: 50px;
          width: grid-width(12, 12, $tablet-gutter, $tablet-outer);
        }

        @media #{media-min-width($desktop)} {
          width: 100%;
        }

        a {
          color: $white;
          transition: color 300ms;

          &:hover {
            color: $dark-gray;
          }
        }
      }

      .client-image-wrapper {
        width: 100%;

        .client-image {
          width: 100%;

          .client-image-file {
            display: block;
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }

  .clients-gallery {
    display: block;

    @media #{media-min-width($desktop)} {
      display: none;
    }
  }

  .clients-gallery-masonry {
    display: none;

    @media #{media-min-width($desktop)} {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .gallery-masonry-column {
      width: grid-width(4, 12, $desktop-gutter, $desktop-outer);
    }
  }

  .clients-gallery-hide {
    display: none;
  }

  .gallery-view-more-wrapper {
    padding-bottom: 50px;
    padding-top: 50px;
    text-align: center;

    .gallery-view-more {
      @include brown-normal;
      cursor: pointer;
      display: inline-block;
      font-size: 10px;
      letter-spacing: 3px;
      line-height: 21px;
      text-transform: uppercase;
      transition: color 300ms;

      &:hover {
        color: $dark-gray;
      }

      &.hide {
        display: none;
      }
    }
  }
}
