// svg-wrapper PHONE
$svg-wrapper-phone: 40;

// svg-wrapper TABLET
$svg-wrapper-tablet: 45;

// home-slideshow.centralized TABLET
$home-slideshow-centralized-width-tablet: calc(#{grid-width(12, 12, $tablet-gutter, $tablet-outer)} - #{$tablet-gutter * 2}px);

// home-slideshow.centralized DESKTOP
$home-slideshow-centralized-width-desktop: calc(#{grid-width(8, 12, $desktop-gutter, $desktop-outer)} + #{$desktop-gutter * 2}px);

// carousel-boxes-wrapper PHONE
$carousel-boxes-wrapper-min-height-phone: 268;
$carousel-boxes-wrapper-height-phone: calc(100vh - #{$header-height-phone}px);

// carousel-boxes-wrapper TABLET
$carousel-boxes-wrapper-min-height-tablet: 720;
$carousel-boxes-wrapper-height-tablet: calc(100vh - #{$header-height-tablet}px);

// carousel-boxes-wrapper DESKTOP
$carousel-boxes-wrapper-min-height-desktop: 640;
$carousel-boxes-wrapper-height-desktop: calc(100vh - #{$header-height-desktop}px);

// close-drop-down PHONE
$close-drop-down-right-phone: 20;
$close-drop-down-size-phone: 10;
$close-drop-down-top-phone: 32;

// close-drop-down TABLET
$close-drop-down-right-tablet: 2 * $close-drop-down-right-phone;
$close-drop-down-top-tablet: 2 * $close-drop-down-top-phone;

// close-drop-down DESKTOP
$close-drop-down-right-desktop: 50;

// drop-down-wrapper PHONE
$drop-down-wrapper-min-height-phone: calc(100vh - #{$header-height-phone}px);
$drop-down-wrapper-padding-top-phone: #{(2 * $close-drop-down-top-phone) + $close-drop-down-size-phone}px;

// drop-down-wrapper TABLET
$drop-down-wrapper-padding-top-tablet: 100;

// email-capture-title PHONE
$email-capture-title-font-size-phone: 28;
$email-capture-title-line-height-phone: 36;
$email-capture-title-margin-bottom-phone: 25;

// email-capture-subtitle PHONE
$email-capture-subtitle-font-size-phone: 10;
$email-capture-subtitle-margin-bottom-phone: 50;

// mc-field-name-email PHONE
$mc-field-name-email-border-size-phone: 1;
$mc-field-name-email-font-size-phone: 16;
$mc-field-name-email-padding-left-right-phone: 10;
$mc-field-name-email-padding-top-bottom-phone: 20;

// mc-field-submit PHONE
$mc-field-submit-phone-font-size-phone: 10;
$mc-field-submit-phone-padding-bottom-phone: 44;
$mc-field-submit-phone-padding-top-phone: 50;

// mc-field-email PHONE
$mc-field-email-margin-bottom-phone: 50 + $mc-field-submit-phone-font-size-phone + $mc-field-submit-phone-padding-bottom-phone + $mc-field-submit-phone-padding-top-phone;

// mc-field-email TABLET
$mc-field-email-margin-bottom-tablet: 100 + $mc-field-submit-phone-font-size-phone + $mc-field-submit-phone-padding-bottom-phone + $mc-field-submit-phone-padding-top-phone;

// start-home-svg PHONE
$start-home-svg-height-phone: $svg-wrapper-height-phone;

// start-home-svg TABLET
$start-home-svg-height-tablet: $svg-wrapper-height-tablet;

// carousel-nav PHONE
$carousel-nav-width-phone: 25;
$carousel-nav-height-phone: 25;

// carousel-nav TABLET
$carousel-nav-width-tablet: 35;

// carousel-nav DESKTOP
$carousel-nav-width-desktop: 53;

// scroll-to-next-bottom PHONE
$scroll-to-next-bottom-phone: 30;
$scroll-to-next-height-phone: 14;
$scroll-to-next-width-phone: 25;

// scroll-to-next-bottom DESKTOP
$scroll-to-next-bottom-desktop: 50;

.page-template-home {
  background-color: $black;
  max-height: 100vh;
  overflow: hidden;

  &.black-logo {
    .home-logo {
      .svg-wrapper {
        svg {
          fill: $black;
        }
      }
    }
  }

  &.white-logo {
    .home-logo {
      .svg-wrapper {
        svg {
          fill: $white;
        }
      }
    }
  }

  &.home-started {
    max-height: initial;
    overflow: initial;
  }

  #header {
    opacity: 0;

    &.fade-in {
      opacity: 1;
      transition: opacity 1000ms 1000ms, top 200ms;

      #navigation {
        opacity: 1;
        transition: opacity 1000ms 2000ms, top 200ms;
      }
    }

    &.fade-in-fast {
      opacity: 1;
      transition: opacity 1000ms, top 200ms;

      #navigation {
        opacity: 1;
      }
    }

    #navigation {
      opacity: 0;
    }
  }

  .home-slideshow {
    height: 100vh;
    opacity: 0;
    position: absolute;
    width: 100%;
    opacity: 0.25;

    &.fade-in {
      opacity: 1;
      transition: opacity 1000ms 500ms;
    }

    &.fade-out {
      opacity: 0;
      transition: opacity 1000ms 1000ms;
    }

    &.hide {
      display: none;
    }

    .slideshow-item {
      cursor: pointer;
      opacity: 0;
      transition: opacity 2000ms;
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;

      &.active {
        opacity: 1;
      }

      &.half-width-right {
        .slideshow-item-image {
          background-size: cover;
          height: 100%;
          top: 0;
          right: 0;
          width: 50%;
          background-position: center;
        }
      }

      &.half-width-left {
        .slideshow-item-image {
          background-size: cover;
          height: 100%;
          top: 0;
          left: 0;
          width: 50%;
          background-position: center;
        }
      }

      &.centralized {
        .slideshow-item-image {
          height: 100%;

          @media #{media-min-width($tablet)} {
            left: 50%;
            transform: translateX(-50%);
            width: $home-slideshow-centralized-width-tablet;
          }

          @media #{media-min-width($desktop)} {
            width: $home-slideshow-centralized-width-desktop;
            background-size: cover;
            background-position: center;
          }
        }
      }

      &.fullscreen {
        .slideshow-item-image {
          background-position: center;
          background-size: cover;
          height: 100%;
          left: 0;
          top: 0;
          width: 100%;
        }
      }

      .slideshow-item-image {
        height: 100%;
        width: 100%;
        position: absolute;
        background-repeat: no-repeat
      }
    }
  }

  #start-home {
    @include z-sky;
    cursor: pointer;
    display: inline-block;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;

    &.fade-in {
      opacity: 1;
      transition: opacity 500ms 2000ms;
    }

    &.fade-out {
      opacity: 0;
      transition: opacity 800ms;
    }

    &.hide {
      display: none;
    }

    .svg-wrapper {
      display: block;
      text-align: center;
      width: 100%;

      svg {
        display: inline-block;
        fill: $white;
        height: #{$start-home-svg-height-tablet}px;
        transition: fill 300ms;
        width: auto;

        @media #{media-min-width($tablet)} {
          height: #{$start-home-svg-height-tablet}px;
        }

        &:hover {
          fill: $dark-gray;
        }
      }
    }
  }

  .home-carousel {
    opacity: 0;
    padding-top: #{$header-height-phone}px;
    position: relative;

    @media #{media-min-width($tablet)} {
      padding-top: #{$header-height-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      padding-top: #{$header-height-desktop}px;
    }

    &.fade-in {
      opacity: 1;
      transition: opacity 1000ms 1000ms;
    }

    &.fade-in-fast {
      opacity: 1;
      transition: opacity 1000ms;
    }

    .carousel-boxes-wrapper {
      height: #{$carousel-boxes-wrapper-height-phone};
      min-height: #{$carousel-boxes-wrapper-min-height-phone}px;
      position: relative;
      width: 100%;

      @media #{media-min-width($tablet)} {
        height: #{$carousel-boxes-wrapper-height-tablet};
        min-height: #{$carousel-boxes-wrapper-min-height-tablet}px;
      }

      @media #{media-min-width($desktop)} {
        height: #{$carousel-boxes-wrapper-height-desktop};
        min-height: #{$carousel-boxes-wrapper-min-height-desktop}px;
      }

      .home-carousel-box {
        background-color: $black;
        background-repeat: no-repeat;
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 2000ms;
        width: 100%;
        background-position-y: top;

        &.ratio-mobile-portrait {
          background-size: auto 100%;
        }

        &.ratio-tablet-portrait {
          @media #{media-min-width($tablet)} {
            background-size: auto 100%;
          }
        }

        &.ratio-desktop-portrait {
          @media #{media-min-width($desktop)} {
            background-size: auto 100%;
          }
        }

        &.ratio-mobile-landscape {
          background-size: 100% auto;
          background-position: center center;
        }

        &.ratio-tablet-landscape {
          @media #{media-min-width($tablet)} {
            background-size: 100% auto;
            background-position: center center;
          }
        }

        &.ratio-desktop-landscape {
          @media #{media-min-width($desktop)} {
            background-size: 100% auto;
            background-position: center center;
          }
        }

        &.position-mobile-left {
          background-position-x: left;
        }

        &.position-mobile-center {
          background-position-x: center;
        }

        &.position-mobile-right {
          background-position-x: right;
        }

        &.position-tablet-left {
          @media #{media-min-width($tablet)} {
            background-position-x: left;
          }
        }

        &.position-tablet-center {
          @media #{media-min-width($tablet)} {
            background-position-x: center;
          }
        }

        &.position-tablet-right {
          @media #{media-min-width($tablet)} {
            background-position-x: right;
          }
        }

        &.position-desktop-left {
          @media #{media-min-width($desktop)} {
            background-position-x: left;
          }
        }

        &.position-desktop-center {
          @media #{media-min-width($desktop)} {
            background-position-x: center;
          }
        }

        &.position-desktop-right {
          @media #{media-min-width($desktop)} {
            background-position-x: right;
          }
        }

        &.visible {
          opacity: 1;
        }
      }
    }

    .home-carousel-navigation {
      .carousel-nav-next,
      .carousel-nav-prev {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: #{$carousel-nav-width-phone}px;

        @media #{media-min-width($tablet)} {
          width: #{$carousel-nav-width-tablet}px;
        }

        @media #{media-min-width($desktop)} {
          width: #{$carousel-nav-width-desktop}px;
        }

        &:hover {
          svg {
            fill: $dark-gray;

            line {
              stroke: $dark-gray;              
            }
          }
        }

        svg {
          display: block;
          width: 100%;
          height: auto;
          fill: $white;
          transition: fill 300ms;

          line {
            transition: stroke 300ms;
            fill: none;
            stroke-width: 1.5px;
            stroke: $white;
          }
        }
      }

      .carousel-nav-prev {
        left: #{$phone-outer / 2}px;
        transform: translateY(-50%) rotate(180deg);

        @media #{media-min-width($tablet)} {
          left: #{($tablet-outer / 2) - ($carousel-nav-height-phone / 2)}px;
        }

        @media #{media-min-width($desktop)} {
          left: #{($desktop-outer / 2) - ($carousel-nav-height-phone / 2)}px;
        }
      }

      .carousel-nav-next {
        right: #{$phone-outer / 2}px;
        transform: translateY(-50%);

        @media #{media-min-width($tablet)} {
          right: #{($tablet-outer / 2) - ($carousel-nav-height-phone / 2)}px;
        }

        @media #{media-min-width($desktop)} {
          right: #{($desktop-outer / 2) - ($carousel-nav-height-phone / 2)}px;
        }
      }
    }

    .scroll-to-next {
      bottom: #{$scroll-to-next-bottom-phone}px;
      cursor: pointer;
      display: block;
      height: #{$scroll-to-next-height-phone}px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: #{$scroll-to-next-width-phone}px;

      @media #{media-min-width($desktop)} {
        bottom: #{$scroll-to-next-bottom-desktop}px;
      }

      svg {
        fill: $white;
        transition: fill 300ms;

        &:hover {
          fill: $dark-gray;
        }
      }
    }
  }
}
