$fonts-path: '../assets/fonts';

@font-face {
  font-family: 'Schnyder Cond L';
  src: url(#{$fonts-path}/SchnyderCondL-Light.eot);
  src: url(#{$fonts-path}/SchnyderCondL-Light.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/SchnyderCondL-Light.woff2) format('woff2'),
    url(#{$fonts-path}/SchnyderCondL-Light.woff) format('woff'),
    url(#{$fonts-path}/SchnyderCondL-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Schnyder Cond L';
  src: url(#{$fonts-path}/SchnyderCondL-Bold.eot);
  src: url(#{$fonts-path}/SchnyderCondL-Bold.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/SchnyderCondL-Bold.woff2) format('woff2'),
    url(#{$fonts-path}/SchnyderCondL-Bold.woff) format('woff'),
    url(#{$fonts-path}/SchnyderCondL-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Schnyder Cond L';
  src: url(#{$fonts-path}/SchnyderCondL-Demi.eot);
  src: url(#{$fonts-path}/SchnyderCondL-Demi.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/SchnyderCondL-Demi.woff2) format('woff2'),
    url(#{$fonts-path}/SchnyderCondL-Demi.woff) format('woff'),
    url(#{$fonts-path}/SchnyderCondL-Demi.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Canela Text';
  src: url(#{$fonts-path}/CanelaText-Light.eot);
  src: url(#{$fonts-path}/CanelaText-Light.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/CanelaText-Light.woff2) format('woff2'),
    url(#{$fonts-path}/CanelaText-Light.woff) format('woff'),
    url(#{$fonts-path}/CanelaText-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Brown;
  src: url(#{$fonts-path}/Brown-RegularAlt.eot);
  src: url(#{$fonts-path}/Brown-RegularAlt.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/Brown-RegularAlt.woff2) format('woff2'),
    url(#{$fonts-path}/Brown-RegularAlt.woff) format('woff'),
    url(#{$fonts-path}/Brown-RegularAlt.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
