// bridal-carousel TABLET
$bridal-carousel-margin-bottom-tablet: 128;

// carousel-nav PHONE
$carousel-nav-width-phone: 14;
$carousel-nav-height-phone: 25;
$carousel-nav-position-phone: $phone-outer - $carousel-nav-width-phone;

// carousel-nav TABLET
$carousel-nav-position-tablet: $tablet-outer - $carousel-nav-width-phone;

// carousel-nav DESKTOP
$carousel-nav-position-desktop: ($desktop-outer / 2) - ($carousel-nav-width-phone / 2);

// scroll-to-next-bottom PHONE
$scroll-to-next-bottom-phone: 30;
$scroll-to-next-height-phone: 14;
$scroll-to-next-width-phone: 25;

// scroll-to-next-bottom DESKTOP
$scroll-to-next-bottom-desktop: 50;

.page-template-bridal {
  background-color: $white;

  #header {
    background-color: $white;

    .header-logo {
      background-color: $white;

      .svg-wrapper {
        svg {
          fill: $black;
          transition: fill 300ms;

          &:hover {
            fill: $dark-gray;
          }
        }
      }
    }

    #navigation {
      background-color: $white;
      color: $black;

      .close-navigation {
        svg {
          stroke: $black;

          &:hover {
            stroke: $dark-gray;
          }
        }
      }
    }
  }

  #main-content {
    padding-top: #{$header-height-phone}px;

    @media #{media-min-width($tablet)} {
      padding-top: #{$header-height-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      padding-top: #{$header-height-desktop}px;
    }
  }

  .bridal-carousel {
    position: relative;
    width: 100%;

    @media #{media-min-width($tablet)} {
      margin-bottom: #{$bridal-carousel-margin-bottom-tablet}px;
    }

    @media #{media-min-width($tablet)} {
      margin-bottom: 0;
    }

    .carousel-wrapper {
      height: 100%;
      position: relative;
      width: 100%;

      .carousel-image-height,
      .carousel-image-height-mobile {
        display: block;
        height: auto;
        opacity: 0;
        visibility: hidden;
        width: 100%;
      }

      .carousel-image-height {
        display: block;

        @media (max-width: 768px) and (orientation: portrait) {
          display: none;
        }
      }

      .carousel-image-height-mobile {
        display: none;

        @media (max-width: 768px) and (orientation: portrait) {
          display: block;
        }
      }

      .carousel-image {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 1000ms;
        width: 100%;

        &.visible {
          opacity: 1;
        }

        .carousel-image-asset {
          display: block;
          height: auto;
          width: 100%;
        }
      }
    }

    .scroll-to-next {
      bottom: #{$scroll-to-next-bottom-phone}px;
      cursor: pointer;
      display: block;
      height: #{$scroll-to-next-height-phone}px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: #{$scroll-to-next-width-phone}px;

      @media #{media-min-width($desktop)} {
        bottom: #{$scroll-to-next-bottom-desktop}px;
      }

      svg {
        fill: $white;
        transition: fill 300ms;

        &:hover {
          fill: $dark-gray;
        }
      }
    }
  }

  .carousel-nav-prev,
  .carousel-nav-next {
    cursor: pointer;
    display: inline-block;
    height: #{$carousel-nav-height-phone}px;
    position: absolute;
    top: 50%;
    width: #{$carousel-nav-width-phone}px;

    svg {
      display: block;
      fill: $white;
      height: 100%;
      transition: fill 300ms;
      width: 100%;

      &:hover {
        fill: $dark-gray;
      }
    }
  }

  .carousel-nav-prev {
    left: #{$carousel-nav-position-phone}px;
    transform: translateY(-50%) rotate(180deg);

    @media #{media-min-width($tablet)} {
      left: #{$carousel-nav-position-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      left: #{$carousel-nav-position-desktop}px;
    }
  }

  .carousel-nav-next {
    right: #{$carousel-nav-position-phone}px;
    transform: translateY(-50%) rotate(0);

    @media #{media-min-width($tablet)} {
      right: #{$carousel-nav-position-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      right: #{$carousel-nav-position-desktop}px;
    }
  }

  .client-lists-gallery {
    .client-list-wrapper {
      .client-bridal-list {
        .client-item {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          .client-text {
            line-height: 32px;
          }
        }
      }
    }
  }
}
