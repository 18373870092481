.page-template-home,
.page-template-shop {
  .email-capture-drop-down {
    @include z-hills;
    background-color: $black;
    color: $white;
    height: auto;
    left: 0;
    min-height: 100vh;
    padding-top: #{$header-height-phone}px;
    position: fixed;
    top: -150%;
    transition: top 600ms;
    width: 100%;

    @media #{media-min-width($tablet)} {
      min-height: initial;
    }

    &.show {
      top: 0;
      transition: top 600ms 3000ms;

      &.hide-logo {
        @media #{media-min-width($tablet)} {
          transition: top 200ms;
          top: -100px;
        }
      }
    }

    &.scrolled {
      @media #{media-min-width($tablet)} {
        transition: top 200ms !important;
      }
    }

    &.signup-success {
      min-height: auto;

      &.show {
        top: 0;
        transition: top 600ms;

        &.hide-logo {
          @media #{media-min-width($tablet)} {
            transition: top 200ms;
            top: -100px;
          }
        }
      }

      &.scrolled {
        @media #{media-min-width($tablet)} {
          transition: top 200ms !important;
        }
      }

      .drop-down-wrapper {
        min-height: auto;
        padding-bottom: 25px;
        padding-top: 75px;

        @media #{media-min-width($tablet)} {
          padding-bottom: 25px;
          padding-top: 100px;
        }

        .email-capture-title {
          @media #{media-min-width($tablet)} {
            width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
          }

          @media #{media-min-width($desktop)} {
            width: grid-width(8, 12, $desktop-gutter, $desktop-outer);
          }
        }
      }
    }

    .drop-down-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      min-height: $drop-down-wrapper-min-height-phone;
      padding-top: #{$drop-down-wrapper-padding-top-phone}px;
      position: relative;
      border-bottom: solid 1px $white-50;

      @media #{media-min-width($tablet)} {
        min-height: initial;
        padding-top: #{$drop-down-wrapper-padding-top-tablet}px;
      }

      .close-drop-down {
        cursor: pointer;
        display: block;
        height: #{$close-drop-down-size-phone}px;
        position: absolute;
        right: #{$close-drop-down-right-phone}px;
        top: #{$close-drop-down-top-phone}px;
        width: #{$close-drop-down-size-phone}px;

        @media #{media-min-width($tablet)} {
          right: #{$close-drop-down-right-tablet}px;
          top: #{$close-drop-down-top-tablet}px;
        }

        @media #{media-min-width($desktop)} {
          right: #{$close-drop-down-right-desktop}px;
        }

        svg {
          display: block;
          fill: none;
          height: 100%;
          stroke: $white;
          transition: stroke 300ms;
          width: 100%;

          &:hover {
            stroke: $dark-gray;
          }
        }
      }

      .email-capture-title {
        @include margin-left-right-auto;
        @include schnyder-cond-l-light;
        font-size: #{$email-capture-title-font-size-phone}px;
        line-height: #{$email-capture-title-line-height-phone}px;
        margin-bottom: #{$email-capture-title-margin-bottom-phone}px;
        text-align: center;
        width: grid-width(4, 4, $phone-gutter, $phone-outer);

        @media #{media-min-width($tablet)} {
          width: grid-width(6, 12, $tablet-gutter, $tablet-outer);
        }

        @media #{media-min-width($desktop)} {
          width: grid-width(6, 12, $desktop-gutter, $desktop-outer);
        }
      }

      .email-capture-subtitle {
        @include brown-normal;
        @include margin-left-right-auto;
        font-size: #{$email-capture-subtitle-font-size-phone}px;
        letter-spacing: #{$email-capture-subtitle-font-size-phone / 3.33}px;
        line-height: #{$email-capture-subtitle-font-size-phone}px;
        margin-bottom: #{$email-capture-subtitle-margin-bottom-phone}px;
        text-align: center;
        text-transform: uppercase;
        width: grid-width(4, 4, $phone-gutter, $phone-outer);

        @media #{media-min-width($tablet)} {
          width: grid-width(4, 12, $tablet-gutter, $tablet-outer);
        }

        @media #{media-min-width($desktop)} {
          width: grid-width(4, 12, $desktop-gutter, $desktop-outer);
        }
      }

      .mc-field-name,
      .mc-field-email {
        @include canela-text-light;
        @include margin-left-right-auto;
        @include placeholder($white);
        border-bottom: solid #{$mc-field-name-email-border-size-phone}px $white-50;
        box-sizing: border-box;
        display: block;
        font-size: #{$mc-field-name-email-font-size-phone}px;
        line-height: #{$mc-field-name-email-font-size-phone}px;
        padding-bottom: #{$mc-field-name-email-padding-top-bottom-phone}px;
        padding-left: #{$mc-field-name-email-padding-left-right-phone}px;
        padding-right: #{$mc-field-name-email-padding-left-right-phone}px;
        padding-top: #{$mc-field-name-email-padding-top-bottom-phone}px;
        width: grid-width(4, 4, $phone-gutter, $phone-outer);

        @media #{media-min-width($tablet)} {
          width: grid-width(10, 12, $tablet-gutter, $tablet-outer);
        }

        @media #{media-min-width($desktop)} {
          width: grid-width(10, 12, $desktop-gutter, $desktop-outer);
        }
      }

      .mc-field-name {
        border-top: solid #{$mc-field-name-email-border-size-phone}px $white-50;
      }

      .mc-field-email {
        margin-bottom: #{$mc-field-email-margin-bottom-phone}px;

        @media #{media-min-width($tablet)} {
          margin-bottom: #{$mc-field-email-margin-bottom-tablet}px;
        }
      }

      .mc-field-submit {
        @include brown-normal;
        bottom: 0;
        display: block;
        font-size: #{$mc-field-submit-phone-font-size-phone}px;
        left: 0;
        letter-spacing: #{$mc-field-submit-phone-font-size-phone / 3.33}px;
        line-height: #{$mc-field-submit-phone-font-size-phone}px;
        padding-bottom: #{$mc-field-submit-phone-padding-bottom-phone}px;
        padding-top: #{$mc-field-submit-phone-padding-top-phone}px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
      }
    }
  }
}
