#main-content {
  position: relative;
  overflow-x: hidden;

  *[scroll-animated] {
    opacity: 0 !important;
    transition: opacity 300ms;

    &.visible {
      opacity: 1 !important;
    }
  }
}
