.page-template-private-clients-weddings {
  background-color: $white;

  #header {
    background-color: $white;

    .header-logo {
      background-color: $white;

      .svg-wrapper {
        svg {
          fill: $black;
          transition: fill 300ms;

          &:hover {
            fill: $dark-gray;
          }
        }
      }
    }

    #navigation {
      background-color: $white;
      color: $black;

      .close-navigation {
        svg {
          stroke: $black;

          &:hover {
            stroke: $dark-gray;
          }
        }
      }
    }
  }

  #main-content {
    padding-top: #{$header-height-phone}px;

    @media #{media-min-width($tablet)} {
      padding-top: #{$header-height-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      padding-top: #{$header-height-desktop}px;
    }
  }

  .private-weddings-content {
    .private-weddings-content-wrapper {
      padding: 128px #{$phone-outer}px 0;
      @include schnyder-cond-l-light;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: $black;
      max-width: 770px;
      margin: 0 auto 60px;

      @media #{media-min-width($tablet)} {
        padding: 128px #{$tablet-outer}px 0;
      }

      @media #{media-min-width($desktop)} {
        padding: 128px #{$desktop-outer}px 0;
      }

      p {
        margin-bottom: 40px;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .private-weddings-link-wrapper {
      margin-bottom: 126px;

      a {
        @include brown-normal;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 4.8px;
        display: block;
        width: fit-content;
        padding-bottom: 9px;
        border-bottom: solid 1px $black;
        margin: 0 auto;
      }
    }
  }

  .private-weddings-carousel {
    margin: 0 auto;
    position: relative;

    &.item-landscape {
      .private-weddings-carousel-images {
        .private-weddings-carousel-item-image {
          width: 100%;
        }
      }

      .private-weddings-carousel-last,
      .private-weddings-carousel-next {
        svg {
          fill: $white;

          line {
            stroke: $white;
          }
        }
      }
    }

    .private-weddings-carousel-images {
      margin: 0 auto;
      max-width: 1280px;
      position: relative;

      .private-weddings-carousel-item-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 45vh;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        transition: opacity 300ms;
        width: 100%;

        @media #{media-min-width($tablet)} {
          height: 78vh;
        }

        @media #{media-min-width($desktop)} {
          height: 90.5vh;
        }

        &.active {
          opacity: 1;
          position: relative;
        }
      }
    }

    .private-weddings-carousel-texts {
      padding: 0 0 100px;
      position: relative;

      .private-weddings-carousel-item-text {
        @include brown-normal;
        box-sizing: border-box;
        color: $black;
        font-size: 10px;
        left: 0;
        letter-spacing: 4.8px;
        line-height: 22px;
        opacity: 0;
        padding: 40px 50px 0;
        position: absolute;
        text-align: center;
        top: 0;
        transition: opacity 300ms;
        width: 100%;

        &.active {
          opacity: 1;
          position: relative;
        }
      }
    }

    .private-weddings-carousel-last,
    .private-weddings-carousel-next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: 25px;

      @media #{media-min-width($tablet)} {
        width: 35px;
      }

      @media #{media-min-width($desktop)} {
        width: 53px;
      }

      &:hover {
        svg {
          fill: $dark-gray;

          line {
            stroke: $dark-gray;
          }
        }
      }

      svg {
        display: block;
        height: auto;
        width: 100%;
        transition: fill 300ms;

        line {
          fill: none;
          stroke-width: 1.5px;
          stroke: $black;
          transition: stroke 300ms;
        }
      }
    }

    .private-weddings-carousel-last {
      left: 14px;
      transform: rotate(180deg) translateY(50%);

      @media #{media-min-width($tablet)} {
        left: 44px;
      }

      @media #{media-min-width($desktop)} {
        left: 72px;
      }
    }

    .private-weddings-carousel-next {
      right: 14px;
      transform: translateY(-50%);

      @media #{media-min-width($tablet)} {
        right: 44px;
      }

      @media #{media-min-width($desktop)} {
        right: 72px;
      }
    }
  }
}
