$about-vertical-space: 75;

.page-template-about {
  background-color: $black;

  #header {
    position: absolute;
  }

  #main-content {
    padding-top: #{$about-vertical-space + $header-height-phone}px;
    position: relative;

    @media #{media-min-width($tablet)} {
      padding-top: #{$about-vertical-space + $header-height-tablet}px;
    }

    @media #{media-min-width($desktop)} {
      padding-top: #{$about-vertical-space + $header-height-desktop}px;
    }
  }

  .about-image {
    background-position: center;
    background-size: cover;
    height: 50.5vh;
    margin-bottom: #{$about-vertical-space}px;
    width: calc(#{grid-width(3, 4, $phone-gutter, $phone-outer)} + #{$phone-outer}px);

    @media #{media-min-width($tablet)} {
      height: 54vh;
      left: 0;
      margin-bottom: 0;
      position: absolute;
      top: #{$about-vertical-space + $header-height-tablet}px;
      width: calc(#{grid-width(4, 12, $tablet-gutter, $tablet-outer)} + #{$tablet-outer}px);
    }

    @media #{media-min-width($desktop)} {
      height: 80vh;
      width: calc(#{grid-width(4, 12, $desktop-gutter, $desktop-outer)} + #{$desktop-outer}px);
    }

    &.fixed {
      @media #{media-min-width($tablet)} {
        position: fixed;
        top: #{$about-vertical-space}px;
      }
    }
  }

  $about-content-wrapper-margin-bottom: 55;

  .about-content-wrapper {
    @include margin-left-right-auto;
    color: $white;
    margin-bottom: #{$about-content-wrapper-margin-bottom}px;
    padding-bottom: #{$about-content-wrapper-margin-bottom}px;
    position: relative;
    width: grid-width(4, 4, $phone-gutter, $phone-outer);

    @media #{media-min-width($tablet)} {
      margin-left: auto;
      margin-right: #{$tablet-outer}px;
      width: grid-width(7, 12, $tablet-gutter, $tablet-outer);
    }

    @media #{media-min-width($desktop)} {
      margin-right: #{$desktop-outer}px;
      width: grid-width(7, 12, $desktop-gutter, $desktop-outer);
    }

    &:after {
      background-color: $white;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    $about-title-cont-font-size-phone: 28;
    $about-title-cont-line-height-phone: 36;
    $about-title-cont-font-size-tablet: 30;
    $about-title-cont-line-height-tablet: 40;
    $about-title-cont-font-size-desktop: 32;
    $about-title-cont-line-height-desktop: 40;
    $about-title-cont-margin-bottom: 55;

    .about-title-cont {
      @include schnyder-cond-l-light;
      font-size: #{$about-title-cont-font-size-phone}px;
      line-height: #{$about-title-cont-line-height-phone}px;
      margin-bottom: #{$about-title-cont-margin-bottom}px;

      @media #{media-min-width($tablet)} {
        font-size: #{$about-title-cont-font-size-tablet}px;
        line-height: #{$about-title-cont-line-height-tablet}px;
      }

      @media #{media-min-width($desktop)} {
        font-size: #{$about-title-cont-font-size-desktop}px;
        line-height: #{$about-title-cont-line-height-desktop}px;
      }
    }

    $about-text-cont-font-size-phone: 12.5;
    $about-text-cont-line-height-phone: 22;
    $about-text-cont-font-size-tablet: 14.5;
    $about-text-cont-line-height-tablet: 24;
    $about-text-cont-font-size-desktop: 16;
    $about-text-cont-line-height-desktop: 26;

    .about-text-cont {
      @include canela-text-light;
      font-size: #{$about-text-cont-font-size-phone}px;
      line-height: #{$about-text-cont-line-height-phone}px;

      @media #{media-min-width($tablet)} {
        font-size: #{$about-text-cont-font-size-tablet}px;
        line-height: #{$about-text-cont-line-height-tablet}px;
      }

      @media #{media-min-width($desktop)} {
        font-size: #{$about-text-cont-font-size-desktop}px;
        line-height: #{$about-text-cont-line-height-desktop}px;
      }

      p {
        margin-bottom: #{$about-text-cont-line-height-phone}px;
        opacity: 0;
        transition: opacity 300ms;

        @media #{media-min-width($tablet)} {
          margin-bottom: #{$about-text-cont-line-height-tablet}px;
        }

        @media #{media-min-width($desktop)} {
          margin-bottom: #{$about-text-cont-line-height-desktop}px;
        }

        &.visible {
          opacity: 1;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  $about-testimonials-wrapper-margin-bottom-phone: 88;
  $about-testimonials-wrapper-margin-bottom-tablet: 90;
  $about-testimonials-wrapper-margin-bottom-desktop: 108;
  $testimonials-item-with-break-line-margin-bottom: 55;
  $testimonials-item-with-break-line-padding-bottom: 50;

  .about-testimonials-wrapper {
    @include margin-left-right-auto;
    color: $white;
    margin-bottom: #{$about-testimonials-wrapper-margin-bottom-phone}px;
    width: grid-width(4, 4, $phone-gutter, $phone-outer);

    @media #{media-min-width($tablet)} {
      margin-bottom: #{$about-testimonials-wrapper-margin-bottom-tablet}px;
      margin-left: auto;
      margin-right: #{$tablet-outer}px;
      width: grid-width(7, 12, $tablet-gutter, $tablet-outer);
    }

    @media #{media-min-width($desktop)} {
      margin-bottom: #{$about-testimonials-wrapper-margin-bottom-desktop}px;
      margin-right: #{$desktop-outer}px;
      width: grid-width(7, 12, $desktop-gutter, $desktop-outer);
    }

    $item-cont-font-size-phone: 12.5;
    $item-cont-line-height-phone: 22;
    $item-cont-font-size-tablet: 14.5;
    $item-cont-line-height-tablet: 24;
    $item-cont-font-size-desktop: 16;
    $item-cont-line-height-desktop: 26;

    .testimonials-item {
      @include canela-text-light;
      margin-bottom: #{$item-cont-line-height-phone * 2}px;

      @media #{media-min-width($tablet)} {
        margin-bottom: #{$item-cont-line-height-tablet * 2}px;
      }

      @media #{media-min-width($desktop)} {
        margin-bottom: #{$item-cont-line-height-desktop * 2}px;
      }

      &.with-break-line {
        margin-bottom: #{$testimonials-item-with-break-line-margin-bottom}px;
        padding-bottom: #{$testimonials-item-with-break-line-padding-bottom}px;
        position: relative;

        &:after {
          background-color: $white;
          bottom: 0;
          content: '';
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          width: 100%;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .item-cont {
        font-size: #{$item-cont-font-size-phone}px;
        line-height: #{$item-cont-line-height-phone}px;
        margin-bottom: #{$item-cont-line-height-phone}px;

        @media #{media-min-width($tablet)} {
          font-size: #{$item-cont-font-size-tablet}px;
          line-height: #{$item-cont-line-height-tablet}px;
          margin-bottom: #{$item-cont-line-height-tablet}px;
        }

        p {
          margin-bottom: #{$item-cont-line-height-phone}px;

          @media #{media-min-width($tablet)} {
            margin-bottom: #{$item-cont-line-height-tablet}px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      $item-credit-font-size-phone: 9;
      $item-credit-line-height-phone: 22;
      $item-credit-font-size-desktop: 10;
      $item-credit-line-height-desktop: 26;

      .item-credit {
        @include brown-normal;
        font-size: #{$item-credit-font-size-phone}px;
        letter-spacing: #{$item-credit-font-size-phone / 3.33}px;
        line-height: #{$item-credit-line-height-phone}px;

        @media #{media-min-width($desktop)} {
          font-size: #{$item-credit-font-size-desktop}px;
          letter-spacing: #{$item-credit-font-size-desktop / 3.33}px; 
          line-height: #{$item-credit-line-height-desktop}px;
        }
      }
    }
  }
}
